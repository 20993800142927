import React from "react"
// import { Link } from "gatsby"
import Breadcrumbs from "../../components/breadcrumbs"
import { useStaticQuery, graphql } from "gatsby"
import FeaturedImg from "../../components/featuredImg"

import Container from 'react-bootstrap/Container'
import Layout from "../../components/layout"

import Button from "../../components/button"
import SideNav from "../../components/sidenav"
import CALinks from './calinks'

import pages from "../../data/pages"

const CustomHaylageSilageHauling = () => {

  // console.log(pages)

  const data = useStaticQuery(graphql`
    query {
      featuredImgDesktop: file(
        relativePath: { eq: "20-MIM-0008_Secondary_CA-Services-6.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      featuredImgMobile: file(
        relativePath: { eq: "20-MIM-0008_Mobile-10.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout id="about-us-page" className="secondary-page">
      <Breadcrumbs currentPath={[pages[0], pages[0].children[2]]}></Breadcrumbs>
      <div className="content-sidebar-wrapper">
        <SideNav header={pages[0].title} menu={pages[0].children}></SideNav>
        <Container className="main-content-left">
          <FeaturedImg
            data={data}
            html={`Custom <br> Haylage & <br> Silage Hauling`}
          ></FeaturedImg>
          <section style={{ marginBottom: "16rem" }}>
            <article>
              <p>
                Among our range of services, MidMichigan does haylage and silage
                hauling. We can rapidly transport crop and dry matter with our
                tractors and large wagons, equipped with floatation tires. The
                benefit of using this specialized machinery is that it decreases
                soil compaction more than semi-trucks, without sacrificing
                speed.
              </p>
              <Button
                style={{ width: "fit-content" }}
                label="Contact Us Today!"
                to={pages[1].path}
                className="smaller-text-mobile"
              ></Button>
            </article>
            <CALinks currentItem={pages[0].children[2].title}></CALinks>
          </section>
        </Container>
      </div>
    </Layout>
  )
}

export default CustomHaylageSilageHauling